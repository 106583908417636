<template>
  <div class="content block-el">
    <template v-if="fetched">
      <PageHeader :title="form.type">
        <template v-slot:actions>
          <li>
            <a
              v-if="!updating"
              @click="update"
              href="javascript:void(0)"
              class="btn btn-success btn-floating"
            >
              <i
                class="fas fa-save text-white font-size-22 v-align-text-bottom"
              ></i>
            </a>
            <a
              v-else
              href="javascript:void(0)"
              class="btn btn-success btn-floating"
              disabled
            >
              <span class="qt-loader qt-loader-mini qt-loader-right"> </span>
            </a>
          </li>
        </template>
      </PageHeader>
      <div class="page-content container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-md-6">
                <div class="card">
                  <div class="card-header">{{$t('adm-component.credentials')}}</div>
                  <div class="card-body">
                    <div class="form-row">
                      <div class="form-group col">
                        <label for="name">{{$tc('generic-str.name', 1)}}</label>
                        <input
                          type="text"
                          class="form-control"
                          :placeholder="$tc('generic-str.name', 1)"
                          v-model="form.name"
                        />
                      </div>
                    </div>
                    <div v-if="form.type == 'sinch'" class="form-row">
                      <div class="form-group col-md-6">
                        <label for="name">UserName</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="UserName"
                          v-model="form.auth.UserName"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label for="name">AuthenticationToken</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="AuthenticationToken"
                          v-model="form.auth.AuthenticationToken"
                        />
                      </div>
                    </div>
                    <div v-else-if="form.type == 'zenvia'" class="form-row">
                      <div class="form-group col-md-6">
                        <label for="name">Username</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Username"
                          v-model="form.auth.Username"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label for="name">{{$t('generic-str.password')}}</label>
                        <input
                          type="text"
                          class="form-control"
                          :placeholder="$t('generic-str.password')"
                          v-model="form.auth.Password"
                        />
                      </div>
                    </div>
                    <div v-else-if="form.type == 'mtexpert'" class="form-row">
                      <div class="form-group col-md-6">
                        <label for="name">Username</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Username"
                          v-model="form.auth.Username"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label for="name">{{$t('generic-str.password')}}</label>
                        <input
                          type="text"
                          class="form-control"
                          :placeholder="$t('generic-str.password')"
                          v-model="form.auth.Password"
                        />
                      </div>
                    </div>
                    <div v-else-if="form.type == 'sendgrid'" class="form-row">
                      <div class="form-group col">
                        <label for="name">API KEY</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="API KEY"
                          v-model="form.auth.api_key"
                        />
                      </div>
                    </div>
                    <div v-else-if="form.type == 'kaleyra'" class="form-row">
                      <div class="form-group col">
                        <label for="name">Secret</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Secret"
                          v-model="form.auth.secret"
                        />
                      </div>
                    </div>
                    <div v-else-if="form.type == 'google'" class="form-row">
                      <div class="form-group col">
                        <label for="name">Secret</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Secret"
                          v-model="form.auth.secret"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div v-if="form.protocol == 'HTTP'" class="card">
                  <div class="card-header">{{$t('generic-str.menu.config')}}</div>
                  <div class="card-body">
                    <div class="form-row">
                      <div class="form-group col-md-4">
                        <label for="name">SMS/{{$t('adm-component.request')}}</label>
                        <input
                          type="text"
                          class="form-control"
                          :placeholder="`SMS/${$tc('adm-component.request', 1)}`"
                          v-model="form.pool_size"
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="name">{{$t('adm-component.request')}}/Seg</label>
                        <input
                          type="text"
                          class="form-control"
                          :placeholder="`${$tc('adm-component.request', 1)}/Seg`"
                          v-model="form.throughput"
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="name">{{$t('adm-component.async-request')}}</label>
                        <input
                          type="text"
                          class="form-control"
                          :placeholder="$t('adm-component.async-request')"
                          v-model="form.window_size"
                        />
                      </div>
                      <div class="form-group col-1 col-md-2">
                        <label for="name">SMS</label>
                        <input
                          v-model="form.sms"
                          class="tgl tgl-light tgl-primary"
                          id="sms"
                          type="checkbox"
                        />
                        <label class="tgl-btn" for="sms"></label>
                      </div>
                      <div class="form-group col-1 col-md-2">
                        <label for="name">Email</label>
                        <input
                          v-model="form.email"
                          class="tgl tgl-light tgl-primary"
                          id="email"
                          type="checkbox"
                        />
                        <label class="tgl-btn" for="email"></label>
                      </div>
                      <div class="form-group col-1 col-md-2">
                        <label for="name">Whatsapp</label>
                        <input
                          v-model="form.whatsapp"
                          class="tgl tgl-light tgl-primary"
                          id="whatsapp"
                          type="checkbox"
                        />
                        <label class="tgl-btn" for="whatsapp"></label>
                      </div>
                      <div class="form-group col-1 col-md-2">
                        <label for="name">RCS</label>
                        <input
                          v-model="form.rcs"
                          class="tgl tgl-light tgl-primary"
                          id="rcs"
                          type="checkbox"
                        />
                        <label class="tgl-btn" for="rcs"></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else-if="form.protocol == 'SMPP'" class="card">
                  <div class="card-header">{{$t('generic-str.menu.config')}}</div>
                  <div class="card-body">
                    <div class="form-row">
                      <div class="form-group col-md-4">
                        <label for="name">SMS/{{$t('adm-component.request')}}</label>
                        <input
                          type="text"
                          class="form-control"
                          :placeholder="`SMS/${$tc('adm-component.request', 1)}`"
                          v-model="form.pool_size"
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="name">{{$t('adm-component.request')}}/Seg</label>
                        <input
                          type="text"
                          class="form-control"
                          :placeholder="`${$tc('adm-component.request', 1)}/Seg`"
                          v-model="form.throughput"
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="name">{{$t('adm-component.async-request')}}</label>
                        <input
                          type="text"
                          class="form-control"
                          :placeholder="$t('adm-component.async-request')"
                          v-model="form.window_size"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div v-if="form.sms" class="card">
                  <div class="card-header" v-if="this.$i18n.locale === 'en'">SMS {{$tc('generic-str.value', 2)}}</div>
                  <div class="card-header" v-else>{{$tc('generic-str.value', 2)}} SMS</div>
                  <div class="card-body">
                    <div class="form-row">
                      <div class="form-group col">
                        <label for="name">Claro</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.values.sms.claro"
                          v-money="money"
                        />
                      </div>
                      <div class="form-group col">
                        <label for="name">Nextel</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.values.sms.nextel"
                          v-money="money"
                        />
                      </div>
                      <div class="form-group col">
                        <label for="name">Oi</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.values.sms.oi"
                          v-money="money"
                        />
                      </div>
                      <div class="form-group col">
                        <label for="name">Tim</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.values.sms.tim"
                          v-money="money"
                        />
                      </div>
                      <div class="form-group col">
                        <label for="name">Vivo</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.values.sms.vivo"
                          v-money="money"
                        />
                      </div>
                      <div class="form-group col">
                        <label for="name">{{$tc('generic-str.other-a', 2)}}</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.values.sms.others"
                          v-money="money"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="form.email" class="card">
                  <div class="card-header" v-if="this.$i18n.locale === 'en'">Email {{$tc('generic-str.value', 2)}}</div>
                  <div class="card-header" v-else>{{$tc('generic-str.value', 2)}} Email</div>
                  <div class="card-body">
                    <div class="form-row">
                      <div class="form-group col">
                        <label for="name">{{$tc('generic-str.message', 1)}}</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.values.email.message"
                          v-money="money"
                        />
                      </div>
                      <div class="form-group col">
                        <label for="name">IP</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.values.email.ip"
                          v-money="money"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="form.whatsapp" class="card">
                  <div class="card-header" v-if="this.$i18n.locale === 'en'">Whatsapp {{$tc('generic-str.value', 2)}}</div>
                  <div class="card-header" v-else>{{$tc('generic-str.value', 2)}} Whatsapp</div>
                  <div class="card-body">
                    <div class="form-row">
                      <div class="form-group col">
                        <label for="name">{{$tc('generic-str.message', 1)}}</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.values.whatsapp.message"
                          v-money="money"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="form.rcs" class="card">
                  <div class="card-header" v-if="this.$i18n.locale === 'en'">RCS {{$tc('generic-str.value', 2)}}</div>
                  <div class="card-header" v-else>{{$tc('generic-str.value', 2)}} RCS</div>
                  <div class="card-body">
                    <h5 class="card-title">{{$t('sms.send-msg.tabs.simple.title')}}</h5>
                    <div class="form-row">
                      <div class="form-group col">
                        <label for="name">Claro</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.values.rcs.simple.claro"
                          v-money="money"
                        />
                      </div>
                      <div class="form-group col">
                        <label for="name">Nextel</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.values.rcs.simple.nextel"
                          v-money="money"
                        />
                      </div>
                      <div class="form-group col">
                        <label for="name">Oi</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.values.rcs.simple.oi"
                          v-money="money"
                        />
                      </div>
                      <div class="form-group col">
                        <label for="name">Tim</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.values.rcs.simple.tim"
                          v-money="money"
                        />
                      </div>
                      <div class="form-group col">
                        <label for="name">Vivo</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.values.rcs.simple.vivo"
                          v-money="money"
                        />
                      </div>
                      <div class="form-group col">
                        <label for="name">{{$tc('generic-str.other-a', 2)}}</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.values.rcs.simple.others"
                          v-money="money"
                        />
                      </div>
                    </div>
                    <h5 class="card-title">{{$t('adm-component.session')}}</h5>
                    <div class="form-row">
                      <div class="form-group col">
                        <label for="name">Claro</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.values.rcs.session.claro"
                          v-money="money"
                        />
                      </div>
                      <div class="form-group col">
                        <label for="name">Nextel</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.values.rcs.session.nextel"
                          v-money="money"
                        />
                      </div>
                      <div class="form-group col">
                        <label for="name">Oi</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.values.rcs.session.oi"
                          v-money="money"
                        />
                      </div>
                      <div class="form-group col">
                        <label for="name">Tim</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.values.rcs.session.tim"
                          v-money="money"
                        />
                      </div>
                      <div class="form-group col">
                        <label for="name">Vivo</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.values.rcs.session.vivo"
                          v-money="money"
                        />
                      </div>
                      <div class="form-group col">
                        <label for="name">{{$tc('generic-str.other-a', 2)}}</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="form.values.rcs.session.others"
                          v-money="money"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="static qt-block-ui" style="padding: 120px" />
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import Tab from '@/directives/Tab';
import PageHeader from '@/components/PageHeader.vue';
import ProviderService from '@/services/admin/provider.service';
import { mask } from 'vue-the-mask';

export default {
  name: 'ShowProvider',
  components: {
    PageHeader,
  },
  directives: {
    Tab,
    mask,
  },
  data() {
    return {
      fetched: false,
      isSending: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 4,
        masked: false,
      },
      updating: false,
      form: {},
      searching: false,
      pages: 1,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.fetched = false;
      ProviderService.getProvider(this.$route.params.id).then(
        (provider) => {
          this.form = provider;
          this.fetched = true;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    ucfirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    update() {
      this.updating = true;
      ProviderService.updateProvider(this.form.id, this.form)
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('adm-component.supp-updated'),
              type: 'success',
            });
            this.$router.push('./');
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.updating = false;
        });
    },
    searchAddress() {
      if (this.form.postal_code && this.form.postal_code.length === 9) {
        this.searching = true;
        axios
          .get(`https://viacep.com.br/ws/${this.form.postal_code}/json/`)
          .then((response) => {
            this.searching = false;
            this.form.street = response.data.logradouro;
            this.form.district = response.data.bairro;
            this.form.city = response.data.localidade;
            this.form.province = response.data.uf;
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.loading-cep {
  right: 40px;
  top: 40px;
  position: absolute;
  padding: initial;
}
</style>
